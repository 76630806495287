/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-02",
    versionChannel: "nightly",
    buildDate: "2023-08-02T00:22:54.390Z",
    commitHash: "006393c5816e68b54c6720fcd8e657009cfb90d8",
};
